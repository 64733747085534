
/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}  */

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}  */

@tailwind base;
@tailwind components;
@tailwind utilities;

.file-input {
  /* font-size: 3.5vw;  */
  font-size: 0.8rem;
}

.truncated {
  white-space: nowrap;         /* Prevent text from wrapping */
  overflow: hidden;            /* Hide overflow text */
  text-overflow: ellipsis;     /* Add ellipsis for overflowed text */
  max-width: 170px;            /* Set a max width based on your design */
}

/* styles.css */
.loader {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #3498db;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  animation: spin 1s linear infinite;
  margin: 1rem auto;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

.service-card {
  border-radius: 16px;
}

.service-image img {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.loader {
  border: 8px solid rgba(255, 255, 255, 0.2); /* Light gray */
  border-top: 8px solid #2563eb; /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}
